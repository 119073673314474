<template>
  <div class="navWrap" >
    <!--导航-->
    <div v-if="showSelect">
    <div class="product_nav flex" v-if="isNavigation">
        <div>
        <span class="icons icon_menu"></span>
        <span>全部服务</span>
      </div>
      <div class="flex flex-1">
        <a @click="index" :class="{active: active===0}">首页</a>
        <a @click="shopSelect" :class="{active: active===2}">广告公司</a>
        <a :href="hrefs"  :class="{active: active===5}" target="_blank" >礼品商城</a>
      </div>
    </div>
    <!-- 导航菜单 -->
    <div class="nav_bar_wrap" :style="{height: navHeight+'px'}" v-if="iShowClass">
      <div class="nav_bar">
          <div class="nav_bar_content">

          <div class="nav_left flex"  @mouseout="hideNavMenuFun" @mouseover="showNavMenuFun">

  </div>


        </div>
      </div>
    </div>


  </div>

  <!-- 以下代码只是在进入商家店铺时展示 -->
    <div v-if="showShop">
      <div class="shopNavWrap">
        <div class="shopNav">
            <ul>
              <li></li>
            </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script type='text/ecmascript-6'>

import * as api from '@/api/api'
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {

    return {
      isNavigation:true, //是否展示顶部导航
      iShowClass:true, //是否展示左侧一级分类
      hideTwoWrap:false,
      // id1:"139",
      // arrList:[], //分类数据
      // arrList2:[], //2,3级数据
      // // arrList3:[], //3级数据


      showSelect:true, //是否显示下拉列表
      showShop:false, //是否展示商家店铺导航
      navHeight: 610,
      showRight: false,
      moreMenuNav: [],
      item: {},
      active: 0,
        hrefs:'',
    }
  },
    computed: {
        ...mapGetters(['user'])
    },
  created(){
    this.getherf();
    // this.getPath();
    //   this.getherf();
    // this.getTree1();
    // this.getTree2();
    //  if (this.$route.path!="") {
    //    this.isNavigation=false;
    //     this.iShowClass=false;
    //   }
    //   if (this.$route.path=="/") {
    //     this.isNavigation=true;
    //     this.iShowClass=true;
    //   }

  },
  mounted() {
    // this.getPath();
  },

  watch:{
    $route(to){
      this.getPath();

      console.log(this.$route.path)
      if (this.$route.path!="") {
       this.isNavigation=false;
        this.iShowClass=false;
      }
      if (this.$route.path=="/") {
        this.isNavigation=true;
        this.iShowClass=true;
      }
    }
  },
  methods: {
    //点击跳转分类页
    goClassPage(id){
      this.$router.push({
        name:"classSearch",
        params:{
          id:id
        }
      })
      this.hideTwoWrap=false;
    },
    goTwoClass(id1){
      this.id1=id1;
      // console.log(typeof(id1))
      this.getTree2(this.id1);
    },
    // getTree1(){ //获取分类结构
    //   this.$http.get('/productCate/getTop').then(res=>{

    //       this.arrList=res.data.data;
    //       console.log(this.arrList)
    //   })
    // },
     getTree1(){ //获取分类结构
      this.$http.get('/productCate/getTopCompose').then(res=>{

          this.arrList=res.data.data;
          // console.log(this.arrList)
      })
    },
    getTree2(){ //获取2，3级结构
      this.$http.get('/productCate/underTopCateByCompose?id='+this.id1).then(res=>{
          // console.log(res.data)
          this.arrList2=res.data.data;
      })
    },
    hideNavMenuFun(){ //隐藏二三级分类
      this.hideTwoWrap=false;
    },
    showNavMenuFun(){
      this.hideTwoWrap=true;
    },
    moveIn(id1){ //移入一级分类
      this.id1=id1;
      this.getTree2(this.id1);
    },




    getPath(){ //获取当前页面路径，判断是否加载下拉三级选择框
      let pathStr=this.$route.path;
      pathStr=pathStr.split("/")[1];
        if(pathStr=="shopPage" ||pathStr=="caseDetail"){
          this.showSelect=false;
          // console.log( document.getElementsByClassName ("header")[0])
        document.getElementsByClassName ("header")[0].classList.add("active1");
          // this.showShop=true;
          return;
        }
        document.getElementsByClassName ("header")[0].classList.remove("active1");
        this.showSelect=true;
        // this.showShop=false;
    },

    // showNavMenuFun(event) {
    //   this.navHeight = 495
    // },
    // hideNavMenuFun() {
    //   this.navHeight = 0
    // },

    showMoreNavMenu(item) {
      this.showRight = true
      if (item) {
        this.firstLevel = item.id
        this.item = item
        item.icon = item.icon.split('_')[0]
        api.productCateUnderTopCate(item.id).then(res => {
          if (res.data.code == 0) {
            this.moreMenuNav = res.data.data
          }
        })
      }
    },
    hideMoreNavMenu(item) {
      this.showRight = false
      if (item) {
        item.icon = item.icon + '_write'
      }
    },
    queryProduct(item, level) {
      let firstLevel = -1
      let secondLevel = -1
      let thirdLevel = -1
      if (level === 1) {
        firstLevel = item.id
      } else if (level === 2) {
        secondLevel = item.secCateId
        firstLevel = item.parentCateId
      } else {
        secondLevel = item.parentCateId
        thirdLevel = item.id
        firstLevel = item.topCateId
      }
      this.hideNavMenuFun()
      this.$router.push({
        name: 'productList',
        query: { firstLevel, secondLevel, thirdLevel, level }
      })
    },

    getherf(){

         this.$http.get('/buyer/genJFShopLink',{params:{mobile:this.user.mobile}}).then(res=>{
             const {data} = res.data
             this.hrefs = data

            // console.log(this.hrefs,res)

            // let tempwindow=undefined;
            // tempwindow = window.open();
            // tempwindow.location.href = ''+this.hrefs+'';


          // var newWin = window.open('/');
          // newWin.location.replace(''+this.hrefs+'');
            // window.open(''+this.hrefs+'');
         })
      },
      shopSelect(){
        this.$router.push('/shopSelect')
      },
      index(){
        this.$router.push('/')
      }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/common.scss';
@mixin icon($m) {
  $url: '../../../assets/image/' + $m;
  @include bgImage($url);
  background-size: contain;
}
*{
  margin: 0;
  padding:0;
}
.active1{
  height: 170px !important;
}
.navWrap {
  position: relative;
  background: #fff;
  border-bottom: 2px solid #d82229;

  // background: red;
  .product_nav {
    max-width: 1200px;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    > div {
      &:first-child {
        width: 200px;
        height: 40px;
        color: white;
        font-size: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #d82229;
        .icon_menu {
          margin-left: 20px;
          @include icon('men00.png');
        }
      }
      &:last-child {
        height: 40px;
        line-height: 40px;
        background: #fff;
        > a {
          width: 120px;
          color: #333;
          cursor: pointer;
          font-weight: 900;
          font-size: 18px;
          text-align: center;
          text-decoration: none;
          background: #fff;
          &.active {
            color: #d82229;
          }
          &:hover {
            color: #d82229;
          }
        }

      }
    }
  }
  .nav_bar_wrap {
    position: absolute;
    // width: 1200px;
    left: 50%;
    margin-left: -600px;
    width: 200px;
    z-index: 100;
    margin-top: 1px;
    transition: all 200ms;
    background:#fff;
      display: none;

    .nav_bar {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .nav_bar_content {
        width: 100%;
        display: flex;
        .nav_left {
          width: 200px;
          flex-wrap: wrap;
        }
        .nav_right {
          flex: 1;
          display: none;
          padding: 15px 10px;
          height: calc(100% - 30px);
          background-color: white;
          overflow-y: auto;
          .nav_right_item {
            display: flex;
            margin-bottom: 20px;
            .nav_right_title {
              width: 120px;
              margin-top: 5px;
              font-size: 14px;
              color: #333;
              text-align: right;
              margin-right: 10px;
              flex-shrink: 0;
              cursor: pointer;
            }
            .nav_right_content {
              flex: 1;
              display: flex;
              line-height: 12px;
              font-size: 12px;
              flex-wrap: wrap;
              border-bottom: 1px dashed #e5e5e5;
              > span {
                padding: 0 10px;
                border-left: 1px solid #e5e5e5;
                margin: 6px 0 10px;
                cursor: pointer;
                &:hover {
                  color: #d82229;
                }
                &.active {
                  color: #d82229;
                }
              }
            }
          }
        }
      }
      .nav_bar_content_right {
        width: 240px;
        height: 495px;
        background: #f5f5f5;
        &_con {
          margin-top: -16px;
          margin-left: 10px;
          background: #fff;
          width: 100%;
          height: 100%;
          ul {
            padding: 10px;
            padding-left: 0px;
            height: auto;
            li {
              width: 50%;
              float: left;
              list-style: none;
              padding: 25px 5px;
              border-top: 1px solid #f5f5f5;
              img {
                width: 80px;
                height: 80px;
                margin-left: 10px;
              }
              p {
                font-size: 12px;
                overflow: hidden; /*自动隐藏文字*/
                text-overflow: ellipsis; /*文字隐藏后添加省略号*/
                white-space: nowrap; /*强制不换行*/
                width: 90%; /*不允许出现半汉字截断*/
                color: #222;
              }
            }
            li:nth-child(odd) {
              border-right: 1px solid #f5f5f5;
            }
          }
        }
      }

    }
  }
}
.navWrap .navLine {
  width: 100vw;
  height: 2px;
  position: absolute;
  top: 45px;
  background: #333;
}

// 以下是商家店铺顶部导航样式
.shopNavWrap{
  width: 100%;
  height: 40px;
  background: #333;
  .shopNav{
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    // background: red;
  }
}


.flex{
  width: 100%;
  position: relative;
  .firstWrap{
    display: flex;
    flex-direction: column;
    // padding:0 20px;
    >:nth-child(2){
      >div{
        padding:0 20px;
      }
    }
    >:nth-child(1){
      // background: red;
      // padding-left: 20px;
      margin-left: 20px;
      margin-top: 5px;
      line-height: 38px;
      font-size: 16px;
      color: #333;
      font-weight: 900;
      }
     .firstBox{
        // margin-bottom: 20px;
        width: 200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px dashed #e5e5e5;
        line-height: 31px;
        font-size: 14px;
        color: #333;
        >div{
          width:100%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         >:nth-child(1){
           margin-right: 20px;
         }
         &:hover{
           background: #eee;
         }
         span{
          width: 60px;
          text-align: center;
          font-size: 14px;
          color: #333;
          text-align: left;
          &:hover{
            color: #d82229;
            cursor: pointer;
          }
        }
        }


      }

  }



  .twoWrap{
    width: 730px;
    position:absolute;
    left: 200px;
    top:1px;
    z-index: 10;
    background: #fff;
    padding:10px 0 20px 0;
    border: 2px solid #d82229;
    // border-top: 3px solid #d82229;
    border-top: none;
    min-height: 610px;
    dl{
      display: flex;
      justify-content:flex-start;
      padding: 0 60px 0 30px;
      dt{
        height: 26px;
        width: 90px;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 30px;
        font-size: 14px;
        color: #333;
        line-height: 26px;
        font-weight: 900;
        margin-top: 12px;
        cursor: pointer;
        &:hover{
          color: #d82229;
        }
      }
      dd{
          width: 530px;
          border-bottom: 1px dashed #e5e5e5;
          padding: 12px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          span{
            margin-right: 20px;
            font-size: 14px;
            color: #666;
            line-height: 26px;
            cursor: pointer;
            &:hover{
              color: #d82229;
            }
          }
      }
    }
  }
}

</style>
