<template>
    <div class="recommendShopWrap">
        <h2>孔雀推荐</h2>

        <div>

        </div>

        <div class="tab">
            <el-tabs @tab-click="handleClick" v-model="activeName">

                <el-tab-pane :key="item.name"
                             :label="item.name"
                             :name="item.name"
                             v-for="(item) in recommendList"
                >
                    <!-- 无推荐产品提示语 -->

                    <div class="defaultPage" v-show="ShopListContent.length==0">
                        <img alt="" src="../../../assets/icon/tuijiandefault.png">
                        <div>
                            <span>当前地区暂未入驻商家</span>
                            <span>请切换城市查看其他城市的入驻商家</span>
                        </div>
                    </div>

                    <div :key="i1" class="recommend-list-wrap" v-for="(item1,i1) in ShopListContent">
                        <li :class="[ (typeof item1.productList == 'undefined' || item1.productList.length <= 0)  ? 'recommend-list2' : 'recommend-list1' ]"
                            class="recommend-list">
                            <div @click="goshop(item1.storeId)" class="shopAd">
                                <div class="shopAd-content">
                                    <div class="shopAd-content-left">

                                        <img :src="item1.logoUrl | imagePath" @click="goshop(item1.storeId)" alt="">
                                    </div>
                                    <div class="shopAd-content-right">
                                        <div class="opens">
                                            <span @click="goshop(item1.storeId)">{{item1.storeName}}</span>
                                            <span v-if="item1.sellerRank==3">v3</span>
                                            <span v-if="item1.sellerRank==2">v2</span>
                                            <span v-if="item1.sellerRank==1">v1</span>
                                            <span>
                                  <img alt="" src="../../../assets/icon/ying.png">
                                </span>
                                            <span>
                                  <img alt="" src="../../../assets/icon/renzheng.png" v-if="item1.pay">
                                </span>
                                            <span>
                                  <img alt="" src="../../../assets/icon/location.png">
                                </span>
                                            <span>
                                  {{item1.address}}
                                </span>
                                            <span>
                                  <img alt="" src="../../../assets/icon/shutiao.png">
                                </span>
                                            <span>
                                  在售产品：{{item1.storeProductCount}}
                                </span>
                                            <span>
                                  成交量：{{item1.salesVolume}}
                                </span>
                                            <span>
                                  服务案例：{{item1.storeProductCaseCount}}
                                </span>
                                        </div>
                                        <div class="server">
                                            <div class="server-title">
                                                擅长服务：
                                            </div>
                                            <div class="server-list">
                                    <span :key="i2" v-for="(item2,i2) in item1.serverList">
                                      {{item2}}
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button @click="goshop(item1.storeId)" class="shopAd-btn" type="button">进入店铺</button>
                            </div>

                            <div class="productWrap">
                                <div class="productWrap-top">
                                    <div class="productWrap-top-content"
                                         v-if="typeof item1.productList != 'undefined' && item1.productList.length > 0">
                                        <dl :key="i3" v-for="(item3,i3) in item1.productList">
                                            <dt>
                                                <img :src="item3.productPic | imagePath" @click="godetail(item3.storeId,item3.productId)"
                                                     alt="">
                                            </dt>
                                            <dd>
                                                <p @click="godetail(item3.storeId,item3.productId)">{{item3.name}}</p>

                                                <!-- 定价 -->
                                                <p v-if="item3.isBargain==0">
                                                    <span>￥{{item3.price}} </span>

                                                    <!-- 公共产品有单位 -->
                                                    <span v-if="item3.type==0&&item3.commonProductUnit!=''">
                                          /{{item3.commonProductUnit}}</span>
                                                    <!-- 公共产品无单位 -->
                                                    <span v-if="item3.type==0&&item3.commonProductUnit==''">起</span>

                                                    <!-- 自定义产品有单位 -->
                                                    <span v-if="item3.type==1 && item3.selfDefProductUnitPrice!='' ">
                                          /{{item3.selfDefProductUnitPrice}}</span>
                                                    <!-- 自定义产品无单位 -->
                                                    <span v-if="item3.type==1 && item3.selfDefProductUnitPrice=='' ">
                                          起</span>
                                                </p>

                                                <!-- 议价 -->
                                                <p v-if="item3.isBargain==1">
                                                    <span>议价</span>
                                                </p>

                                                <!-- <p v-if="item3.isBargain==0">
                                                  <span>￥{{item3.price}} </span>
                                                  <span v-if="item3.type==0">起</span>

                                                  <span v-else-if="item3.type==1 && item3.selfDefProductUnitPrice!='' ">/{{item3.selfDefProductUnitPrice}}</span>
                                        <span v-else>起</span>
                                    </p>

                                      <p v-else-if="item3.isBargain==1">
                                          <span>议价</span>
                                      </p> -->

                                                <p @click="dialogVisible1 = true;productShow(item3.productPic,item3.name,item3.price,item3.productId,item3.storeId,item1.storeName)">
                                                    快速下单</p>
                                            </dd>
                                        </dl>


                                    </div>
                                    <div class="oneKeywrap"
                                         v-if="typeof item1.productList == 'undefined' || item1.productList.length <= 0">
                                        商家暂未选定产品出售，您可以直接联系商家沟通业务并点击
                                        “
                                        <span class="oneKey">一键支付</span>
                                        ”
                                        快速生成订单 ~

                                    </div>

                                    <div class="productBtnWrap">
                                        <button @click="dialogVisible = true;phone(item1.storeId)" class="shopAd-btn"
                                                type="button">联系商家
                                        </button>
                                        <button @click="dialogVisible2 = true;oneKeyShow(item1.storeName,item1.storeId)" class="shopAd-btn"
                                                type="button">
                                            一键支付
                                        </button>
                                    </div>
                                </div>

                                <div class="otherwrap"
                                     v-if="typeof item1.productList != 'undefined' && item1.productList.length > 0">
                                    <span @click="goshop(item1.storeId)">查看本店其他更多热售产品  >></span>

                                </div>
                            </div>
                        </li>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>

        <img alt="" src="./RecommendShop.vue">

        <div class="quickOrder">
            <el-dialog :visible.sync="dialogVisible" title="">
                <div class="popTop">
                    <p class="storeName">{{store.storeName}}</p>
                    <div class="storeMobile">
                        <div>
            <span>
              <img align="middle" alt="" src="../../../assets/image/phone.png">
            </span>
                            <span>{{store.mobile}}</span>
                        </div>
                        <div class="streFooter">
                            注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>


        <div class="quickOrder1">
            <el-dialog :visible.sync="dialogVisible1" title="">
                <div class="popWrap">
                    <div class="titles">
                        <p>快速下单</p>
                        <p>您</p>
                        <p>已选定产品</p>
                        <p>且已经与商家沟通好价格，直接付款</p>
                        <p>可快速生成订单</p>
                    </div>
                    <div class="productPop">
                        <p>我需要：</p>
                        <div class="product-img">
                            <img :src="productObj.img | imagePath" alt="">
                        </div>
                        <div>
                            <p>{{productObj.name}}</p>
                            <div>
                                <span>参考价：</span>
                                <span>￥{{productObj.price}}</span>&nbsp;
                                <span>起</span>

                            </div>
                        </div>
                    </div>

                    <el-form :model="form" ref="form">
                        <el-form-item label=" 订单金额：" prop="consignee">
                            <el-input
                                    @input="oninput"
                                    maxlength="11"
                                    minlength="1"
                                    placeholder="请输入与商家已沟通好的价格（元）"
                                    v-model="form.price"
                            ></el-input>
                            <!-- <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）"  @input="oninput"></el-input> -->
                        </el-form-item>
                        <el-form-item label="订单备注：" prop="mobile">
                            <el-input placeholder="输入您对本订单的制作/配送/开票等要求说明（选填）" v-model="form.info"></el-input>
                        </el-form-item>


                        <el-form-item class="save">
                            <el-button @click="onSubmit" class="details" type="primary">立即提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </div>

        <div class="quickOrder2">
            <el-dialog :visible.sync="dialogVisible2" title="">
                <div class="popWrap">
                    <div class="titles">
                        <p>一键下单</p>
                        <p>您已经与商家沟通好价格，直接付款</p>
                        <p>可快速生成订单</p>
                    </div>

                    <el-form :model="form" ref="form">
                        <el-form-item label=" 我需要：" prop="consignee">
                            <!-- <el-input v-model="form.requirementDesc" placeholder=""></el-input> -->
                            <el-input
                                    placeholder="简要说明您需要做的业务（包括物料、数量、工艺、服务要求等）"
                                    type="textarea"
                                    v-model="form.info"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item label="订单金额：" prop="mobile">
                            <!-- <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）" maxlength="11" ></el-input> -->
                            <el-input
                                    @input="oninput"
                                    maxlength="11"
                                    minlength="1"
                                    placeholder="请输入与商家协商后的价格（元）"

                                    v-model="form.price"
                            ></el-input>
                        </el-form-item>

                        <div class="protocol">
                            <el-checkbox @change="checkedAll" v-model="checked">
                                <span>我已阅读并同意&nbsp;</span>
                            </el-checkbox>
                            <span class="protocol-word">《孔有礼使用协议》</span>
                        </div>

                        <el-form-item class="save" v-if="isDisable">
                            <el-button class="details" disabled type="primary">立即提交</el-button>
                        </el-form-item>
                        <el-form-item class="save" v-if="isSave">
                            <el-button @click="onSubmit1" class="details" type="primary">立即提交</el-button>
                        </el-form-item>
                    </el-form>

                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import * as api from '@/api/api';

    export default {
        components: {},
        props: {},
        data() {
            return {
                timer: false,
                isSave: true, //保存可用
                isDisable: false, //保存可用
                checked: true,
                //一键下单付款信息
                // form1: {
                //   "info":"", //买家需求
                //   "price": '',//价格
                // },

                //快速下单信息,一键支付信息
                form: {
                    info: '', //备注信息
                    price: "",
                },

                activeName: 'first', //tab默认为第一个
                sum: "会场庆典",
                sum2: "会场庆典",
                sum3: "会场庆典",
                recommendList: [], //孔雀推荐一级分类
                ShopListContent: [], //孔雀推荐主体内容
                param: {
                    lonStr: JSON.parse(sessionStorage.getItem("lng")),
                    latStr: JSON.parse(sessionStorage.getItem("lat")),
                    acode: JSON.parse(sessionStorage.getItem("acode")),
                    cityName: "",
                    pageNum: "0",
                    pageSize: "20",
                    cateId: '0', //tab的id
                },
                dialogVisible: false, //电话弹窗显示与否
                store: {}, //保存弹窗信息的对象
                dialogVisible: false, //联系商家显示与否
                dialogVisible1: false, //快速下单弹窗显示与否
                dialogVisible2: false, //一键支付弹窗显示与否
                productObj: { //点击快速下单展示数据
                    storeName: "",
                    img: "",
                    name: "",
                    price: "",
                    id: "",
                    storeId: ""
                }
            };
        },
        computed: {
            ...mapGetters(['user', 'isLogin', 'keywords'])
        },
        destroyed() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },
        mounted() {
            this.storeList();
            this.getShopList(); //获取商铺产品列表
            this.timer = setTimeout(() => {
                this.param.lonStr = JSON.parse(sessionStorage.getItem("lng"));
                this.param.latStr = JSON.parse(sessionStorage.getItem("lat"));

                if (JSON.parse(sessionStorage.getItem("lng1")) != null) {
                    this.param.lonStr = JSON.parse(sessionStorage.getItem("lng1")); //经度
                    this.param.latStr = JSON.parse(sessionStorage.getItem("lat1")); //纬度
                }

                this.getShopListContent(); //获取商铺产品列表
            }, 500);


        },

        created() {
            this.activeName = "推荐";

            //首次加载默认加载南京
            if (JSON.parse(localStorage.getItem("city")) == null) {
                this.param.cityName = "南京市"
            } else {
                this.param.cityName = JSON.parse(localStorage.getItem("city"));
            }
        },
        methods: {
            //以下一键支付下单
            checkedAll(e) { //选中协议
                if (!e) {
                    this.isDisable = true;
                    this.isSave = false;
                }
                if (e) {

                    this.isDisable = false;
                    this.isSave = true;
                }
            },

            oneKeyShow(storeName, storeId) { //点击一键支付时，保存店铺信息
                if (this.isLogin == "0") {
                    this.$message.error('请先登录!');
                    this.dialogVisible2 = false;
                    this.$router.push("/login");
                }
                this.fastList = {
                    "storeName": storeName,
                    "storeId": storeId,
                }
                console.log(this.fastList)
            },
            // blurHandler() {
            //             if (!this.$check.checkMoney(this.form.price)) {
            //                  this.$message.error("请输入正确的金额格式!");
            //             }
            //         },
            oninput(e) {
                // 通过正则过滤小数点后两位
                this.form.price = (this.form.price.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            },
            onSubmit1() { //一键支付
                debugger
                if (this.$check.trim(this.form.price + '').length === 0) {
                    this.$message.error('请输入订单金额!');
                    return;
                }
                if (!this.$check.checkMoney(this.form.price)) {
                    this.$message.error("请输入订单金额!");
                    return;
                }
                this.fastList.info = this.form.info; //需求信息
                this.fastList.price = this.form.price;//一键支付输入的价格
                this.fastList.fastFlag = "2";
                this.fastList.img = "upload/b060a4a7810743fa8ea8cdc56d124138.png";
                this.fastList.name = "线下业务单";
                this.fastList.sn = "";
                localStorage.setItem("fastList", JSON.stringify(this.fastList));
                this.$router.push({
                    name: "order",
                });
            },


            godetail(storeId, productId) { //首页去产品详情
                this.$router.push({path: '/productDetail', query: {sid: storeId, pid: productId}})
            },
            productShow(img, name, price, id, storeId, storeName) {
                if (this.isLogin == "0") {
                    this.$message.error('请先登录!');
                    this.dialogVisible1 = false;
                    this.$router.push("/login");
                }
                this.productObj.img = img;
                this.productObj.name = name;
                this.productObj.price = price;
                this.productObj.id = id;
                this.productObj.storeId = storeId;
                this.productObj.storeName = storeName;
            },
            onSubmit() { //快速下单提交

                if (this.$check.trim(this.form.price + '').length === 0) {
                    this.$message.error('请输入订单金额!');
                    return;
                }
                if (!this.$check.checkMoney(this.form.price)) {
                    this.$message.error("请输入订单金额!");
                    return;
                }
                let fastList = {
                    name: this.productObj.name,
                    storeId: this.productObj.storeId,
                    storeName: this.productObj.storeName,
                    id: this.productObj.id,
                    img: this.productObj.img,
                    info: this.form.info,
                    price: this.form.price,
                    fastFlag: "0"
                };
                localStorage.setItem("fastList", JSON.stringify(fastList));
                this.$router.push({
                    name: "order",
                });
            },
            phone(storeId) { //联系商家
                this.$http.get('store/info', {params: {storeId: storeId}}).then(res => {
                    this.store = res.data.data;
                })
            },
            goshop(storeId) { //首页列表进店铺
                this.$router.push({
                    name: "shopPage",
                    params: {
                        id: storeId
                    }
                })
            },
            getShopListContent() { //孔雀推荐主题内容
                this.$http.post('/store/product/list', this.param).then(res => {
                    this.ShopListContent = res.data.data.list;
                })
            },
            getShopList() { //
                this.$http.get('pic/adv/config/new/list?code=pc_sy_fltj').then(res => {
                    this.recommendList = res.data.data;
                })
            },


            ...mapMutations({saveStore: 'STORE_INFO'}),
            storeList() {

            },

            handleClick(tab) {
                if (this.activeName == "推荐") {
                    this.getShopListContent(this.param.cateId = "0");
                }
                if (this.activeName == "会务庆典") {
                    this.getShopListContent(this.param.cateId = "461");
                }
                if (this.activeName == "门头装饰") {
                    this.getShopListContent(this.param.cateId = "462");
                }
                if (this.activeName == "图文广告") {
                    this.getShopListContent(this.param.cateId = "445");
                }
                if (this.activeName == "商务印刷") {
                    this.getShopListContent(this.param.cateId = "139");
                }
                if (this.activeName == "广告灯箱") {
                    this.getShopListContent(this.param.cateId = "303");
                }
                if (this.activeName == "广告字") {
                    this.getShopListContent(this.param.cateId = "253");
                }
                if (this.activeName == "标牌标识") {
                    this.getShopListContent(this.param.cateId = "144");
                }
                if (this.activeName == "设计服务") {
                    this.getShopListContent(this.param.cateId = "422");
                }
                if (this.activeName == "IT软件") {
                    this.getShopListContent(this.param.cateId = "545");
                }
                // tab.label=1;
            },
        }
    };
</script>

<style lang='scss' scoped>
    * {
        margin: 0;
        padding: 0;
    }

    h2 {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    h2 {
        font-size: 20px;
        color: #333;
        text-align: left;
        line-height: 20px;
        margin-top: 30px;
    }

    .recommendShopWrap {
        margin: 0 auto;
        width: 1200px;
        // margin-bottom: 20px;
        /deep/ .tab {
            width: 100%;
            // .el-tabs__nav-wrap::after {
            //   display: none;
            // }

            .el-tabs__item.is-active {
                color: #d82229;
            }

            .el-tabs__active-bar {
                height: 4px;
                background-color: #d82229;
            }

            .el-tabs__nav > div {
                &:hover {
                    color: #d82229 !important;
                }
            }

            .el-tabs__item {
                font-size: 18px;
                color: #333;


            }

            .el-tabs__nav {
                height: 60px;
                line-height: 60px;
                margin-top: 5px;
            }

            .el-tabs__content {
                display: block;
            }

            .is-top {
                margin-bottom: 0px;
            }


            .recommend-list-wrap {
                margin-top: 15px;

                .recommend-list { //大列表
                    padding: 0 20px;
                    height: 320px;
                    background: #fff;
                    margin-bottom: 20px;

                    .shopAd { //广告公司信息
                        cursor: pointer;
                        height: 110px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #eee;

                        .shopAd-content { //左侧主体
                            width: 960px;
                            height: 70px;
                            display: flex;
                            justify-content: flex-start;

                            .shopAd-content-left {
                                margin-right: 15px;

                                img {
                                    display: block;
                                    width: 70px;
                                    height: 70px;
                                }
                            }

                            .shopAd-content-right {

                                .opens {
                                    height: 30px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;

                                    span {
                                        margin-right: 8px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    :nth-child(1) {
                                        max-width: 165px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: block;
                                        font-size: 18px;
                                        color: #333;
                                        font-weight: 900;
                                    }

                                    :nth-child(2) {
                                        width: 20px;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 20px;
                                        font-size: 14px;
                                        color: #fff;
                                        background: #ffac00;
                                        border-radius: 2px;
                                        // padding:2px;
                                    }

                                    :nth-child(5) {
                                        margin-right: 6px;
                                        font-size: 14px;
                                        color: #666;
                                        margin-right: 0;
                                    }

                                    :nth-child(6) {
                                        display: block;
                                        font-size: 14px;
                                        margin: 0 10px;
                                        color: #666;
                                        max-width: 320px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        flex-shrink: 0;
                                    }

                                    :nth-child(8), :nth-child(9), :nth-child(10) {
                                        font-size: 14px;
                                        color: #666;
                                        margin-right: 12px;
                                    }
                                }

                                .server {
                                    margin-top: 10px;
                                    height: 30px;
                                    // background: orange;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;

                                    .server-title {
                                        font-size: 14px;
                                        color: #666;
                                        margin-right: 12px;
                                    }

                                    .server-list {
                                        font-size: 12px;
                                        color: #999;

                                        span {
                                            padding: 5px 6px;
                                            margin-right: 14px;
                                            border: 1px solid #e5e5e5;
                                            border-radius: 2px;
                                        }

                                        :last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }

                            }

                        }

                        .shopAd-btn { //右侧进入店铺按钮
                            outline: none;
                            width: 108px;
                            height: 34px;
                            font-size: 16px;
                            color: #ffa219;
                            border-radius: 3px;
                            background: none;
                            border: 1px solid #ffa219;
                            cursor: pointer;
                            margin-right: 35px;
                        }
                    }

                    .otherwrap {
                        line-height: 50px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 14px;
                        color: #999;

                        span {
                            &:hover {
                                color: #d82229;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .recommend-list2 {
                    height: 260px;
                }

                > :last-child {
                    margin-bottom: 0;
                }

                .productWrap {
                    .productWrap-top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;

                        .productWrap-top-content {
                            display: flex;

                            dl {
                                width: 288px;
                                height: 140px;
                                display: flex;
                                margin-right: 20px;
                                justify-content: space-between;

                                dt {
                                    width: 140px;
                                    height: 140px;
                                    overflow: hidden;
                                    flex-shrink: 0;

                                    img {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        color: #000;
                                        cursor: pointer;
                                    }
                                }

                                dd {
                                    width: 139px;
                                    height: 100px;
                                    font-size: 16px;
                                    color: #333;
                                    flex-shrink: 0;

                                    > :nth-child(1) {

                                        margin-top: 11px;
                                        height: 44px;
                                        text-align: left;
                                        cursor: pointer;

                                        &:hover {
                                            color: #d82229;
                                        }
                                    }

                                    > :nth-child(1), > :nth-child(2) {
                                        text-align: left;
                                        line-height: 22px;
                                        color: #333;
                                        font-size: 16px;
                                        word-break: break-all;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }

                                    > :nth-child(2) {
                                        line-height: 34px;

                                        > :nth-child(1) {
                                            color: #ff0a50;
                                            font-weight: 600;
                                        }
                                    }

                                    > :nth-child(3) {
                                        width: 110px;
                                        height: 34px;
                                        outline: none;
                                        background: none;
                                        border-radius: 3px;
                                        border: 1px solid #333;
                                        text-align: cneter;
                                        line-height: 34px;
                                        color: #333;
                                        font-size: 14px;
                                        cursor: pointer;
                                        margin-top: 3px;
                                    }
                                }
                            }


                        }

                        .productBtnWrap {
                            width: 150px;
                            height: 110px;
                            padding-left: 40px;
                            border-left: 1px solid #eeeeee;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-right: 35px;

                            .shopAd-btn { //右侧进入店铺按钮
                                margin-left: auto;
                                outline: none;
                                width: 110px;
                                height: 34px;
                                font-size: 16px;
                                color: #fff;
                                border-radius: 3px;
                                background: none;
                                cursor: pointer;
                                margin-right: 35px;
                                border: 1px solid #d82229;
                                color: #d82229;
                                // border: none;
                            }

                            :nth-child(2) {
                                background: #d82229;
                                color: #fff;
                            }
                        }
                    }

                    .productWrapBtn {
                        outline: none;
                        width: 108px;
                        height: 34px;
                        font-size: 16px;
                        color: #ffa219;
                        border-radius: 3px;
                        background: none;
                        border: 1px solid #ffa219;
                        cursor: pointer;
                        margin-right: 70px;
                    }

                    .oneKeywrap {
                        font-size: 18px;

                        .oneKey {
                            color: #ffac00;
                            font-size: 18px;
                        }
                    }

                }
            }

            .defaultPage {
                width: 100%;
                height: 190px;
                color: #333;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                > div {
                    height: 190px !important;
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    justify-content: center;
                    margin-left: 28px;
                    height: auto;

                    span {
                        text-align: left;
                        margin: 5px;
                    }
                }
            }
        }

    }


    .quickOrder {
        /deep/ .el-button--primary {
            width: 100%;
            height: 100%;
            background: #d82229;
            border: none;
        }

        //以下弹窗样式
        /deep/ .el-dialog {
            width: 440px;
            height: 220px;
            margin-top: 40vh !important;

            .el-dialog__body {
                padding: 0;
            }

            .el-dialog__header {
                background: #f5f5f5;
            }

            .popTop {
                height: 120px;

                .storeName {
                    text-align: center;
                    font-size: 22px;
                    color: #333;
                    height: 44px;
                    line-height: 44px;
                    background: #f5f5f5;
                }

                .storeMobile {
                    height: 66px;
                    line-height: 66px;
                    font-size: 28px;
                    color: #333;
                    background: #f5f5f5;

                    > div {
                        width: 280px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        margin: 0 auto;
                        padding: 0 10px;
                        overflow: hidden;
                        color: #333333;

                        img {
                            display: block;
                        }
                    }

                    .streFooter {
                        text-align: center;
                        height: 80px;
                        font-size: 12px;
                        color: #999;
                        display: flex;
                        justify-content: center;
                        line-height: 24px;
                    }
                }
            }

        }
    }

    /deep/ .quickOrder1 {
        .el-button--primary {
            width: 100%;
            height: 100%;
            background: #d82229;
            border: none;
        }

        .el-dialog {
            height: 510px !important;

            .titles {
                font-size: 16px;

                :last-child {
                    color: #d82229;
                }
            }

            .productPop {
                display: flex;
                height: 150px;
                margin: 20px 0;

                > :nth-child(1) {
                    width: 80px;
                    padding-right: 12px;
                    flex-shrink: 0;
                    text-align: right;
                }

                > :nth-child(2) {
                    width: 150px;
                    margin-right: 20px;
                    flex-shrink: 0;
                    width: 150px;
                    height: 150px;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                > :nth-child(3) {
                    width: 390px;

                    p {

                        font-size: 16px;
                        line-height: 40px;
                        text-align: left;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    > div {
                        line-height: 46px;
                        text-align: left;

                        :nth-child(1) {
                            font-size: 14px;
                        }

                        :nth-child(2) {
                            font-size: 16px;
                            color: #d82229;
                        }

                        :nth-child(3) {
                            font-size: 14px;
                        }
                    }
                }

            }
        }

        .el-dialog .el-dialog__body .el-form-item .el-form-item__label {
            width: 80px !important;
            flex-shrink: 0;
            padding-right: 10px;
            // padding: 0;
        }
    }

    /deep/ .quickOrder2 {
        /deep/ .el-button--primary {
            width: 100%;
            height: 100%;
            background: #d82229;
            border: none;
        }

        .el-form-item__content {
            width: 100%;
        }

        .titles {
            font-size: 16px;

            :last-child {
                color: #d82229;
            }
        }

        .el-textarea {
            width: 100%;
            height: 180px;
        }

        .el-textarea__inner {
            width: 100%;
            height: 180px;
            resize: none;
        }

        .el-dialog {
            height: auto;
            padding-bottom: 40px;
        }

        .el-input {
            width: auto;
        }
    }


    //  /deep/ .el-dialog .el-dialog__body .protocol .protocol-word{
    //    color:#f78705 !important;
    //  }

</style>
