<template>
    <div id="index">
        <!--首页nav导航-->
<!--        <component :is="menu"></component>-->
        <Nav></Nav>
        <div class="main" id="mainId">
            <div class="carousel">
                <div class="upperHalf">
                    <div @mouseout="hideNavMenuFun" @mouseover="showNavMenuFun" class="upperHalf-left">
                        <div :key="i6" class="firstWrap" v-for="(topCateItem,i6) in arrList">
                            <div>{{topCateItem.topCateName}}</div>
                            <div class="firstBox">
                                <div :key="i1" @mouseover="moveIn(item1.compostId)"
                                     v-for="(item1,i1) in topCateItem.underTopCatList">
                                    <!-- item1是每一行数组的数据 -->
                                    <!-- item4是每一个数据 -->
                                    <span :key="i4" @click="goClassPage(item4.id)"
                                          v-for="(item4,i4) in item1.compostList">{{item4.name}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="twoWrap" v-show="hideTwoWrap">
                            <dl :key="i2" v-for="(item2,i2) in arrList2">
                                <dt @click="goClassPage(item2.secCateId)">{{item2.secCateName}}</dt>
                                <dd>
          <span :key="i3" @click="goClassPage(item3.id)" v-for="(item3,i3) in item2.thirdCateList">
            {{item3.name}}
          </span>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="upperHalf-center">
                        <div class="carousel">
                            <div class="block">
                                <el-carousel height="300px" trigger="click">
                                    <el-carousel-item :key="i" v-for="(item,i) in banner">
                                        <h3 class="small">
                                            <!-- {{item.picUrl}} -->
                                            <a :href="item.advUrl"> <img :src="item.picUrl | imagePath"/></a>
                                        </h3>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>

                        <div class="boutiqueArea">
                            <h2>精品专区</h2>
                            <div class="boutiqueArea-list">
                                <dl :key="i3" v-for="(item3,i3) in boutiqueList">
                                    <dt>
                                        <img :src="item3.imgUrl | imagePath"
                                             @click="godetail(item3.storeId,item3.productId)">
                                    </dt>
                                    <dd>
                                        <p>{{item3.productName}}</p>
                                        <div>
                                            <!-- <span>参考价：</span> -->
                                            <span>￥{{item3.price}}</span>
                                            <span>起</span>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <!-- 以上是首页上半部分中间部分-->


                    <div class="upperHalf-right">
                        <div class="avatarWrap">
                            <div class="avatarWrap-top">
                                <div v-if="!isLogin * 1 === 1">
                                    <img src="../../assets/image/touxiang2.png">
                                    <span>欢迎来到孔雀有礼！</span>
                                </div>
                                <div v-if="!isLogin * 1 === 0">
                                    <img src="../../assets/image/touxiang2.png">
                                    <span>Hi,{{user.userName | phoneFmt}}</span>
                                </div>
                                <div>
                                    <button @click="goLogin" type="button" v-show="!isLogin * 1 === 1">登录</button>
                                    <button @click="goRegist" type="button" v-show="!isLogin * 1 === 1">注册</button>
                                    <!-- <div>QQ联系</div> -->
                                </div>
<!--                                <p class="needs">-->
<!--                                    快速发布一条广告需求-->
<!--                                </p>-->
<!--                                <div class="quoteWrap">-->
<!--                                    <el-input placeholder="请输入需求" v-model="input"></el-input>-->
<!--                                    <div @click="dialogVisible = true;clickPrice()">获取报价</div>-->
<!--                                </div>-->
                            </div>

                            <div :key="i1" class="avatarWrap-bottom" v-for="(item1,i1) in AsideAd">
                                <img :src="item1.picUrl | imagePath" @click="goRenting" alt="">
                            </div>
                        </div>
                        <div class="exchange">
                            <div class="integral-wrap">
                                <img :src="topTwoObj | imagePath" alt="">
                                <!-- <p>快速加入孔雀广告网</p>
                                <p>消费多少金额，再送等值礼品！</p>
                                <button type="button">立即获取积分</button> -->
                            </div>

                            <div class="popular">
                                <p>热门兑换中</p>
                                <div class="marquee_box">
                                    <ul :class="{marquee_top:animate}" class="marquee_list">
                                        <li :key="index" v-for="(item2, index) in marqueeList">
                                            <span>{{item2.timeBefore}}</span>&nbsp;
                                            <span>用户</span>
                                            <span>{{item2.phone}}</span>
                                            <span>使用积分兑换了价值</span>
                                            <span>￥{{item2.price}}&nbsp;</span>
                                            <span class="red"> {{item2.goodName}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

<!--            <AdvertisingMap></AdvertisingMap>-->
            <!--广告图-->


            <RecommendShop></RecommendShop>
            <!-- 孔雀推荐 -->

            <div class="hengfu">
                <a :href="advUrl"> <img :src="hengfuObj | imagePath" alt=""></a>
            </div>


        </div>

        <el-dialog :visible.sync="dialogVisible" title="">
            <div class="popWrap">
                <div class="titles">
                    <p>一句话描述您的需求</p>
                    <p>孔雀有礼为您精准匹配优质的专业服务商</p>
                </div>

                <el-form :model="form" ref="form">
                    <el-form-item label=" 我需要：" prop="consignee">
                        <el-input placeholder="一句话描述您的需求" v-model="form.requirementDesc"></el-input>
                    </el-form-item>
                    <el-form-item label="联系号码：" prop="mobile">
                        <el-input maxlength="11" placeholder="请输入该需求联系人手机号" v-model="form.phone"></el-input>
                    </el-form-item>

                    <div class="protocol">
                        <el-checkbox @change="checkedAll" v-model="checked">
                            <span>我已阅读并同意&nbsp;</span>
                        </el-checkbox>
                        <span class="protocol-word">《孔雀有礼使用协议》</span>
                    </div>

                    <el-form-item @click.native="onSubmit" class="save" v-if="isDisable">
                        <el-button class="details" disabled type="primary">免费发布</el-button>
                    </el-form-item>
                    <el-form-item @click.native="onSubmit" class="save" v-if="isSave">
                        <el-button class="details" type="primary">免费发布</el-button>
                    </el-form-item>
                </el-form>

            </div>
        </el-dialog>
    </div>
</template>

<script>

    import {mapGetters, mapMutations} from 'vuex'
    import * as api from '@/api/api'
    import AdvertisingMap from '@/components/home/advertisingMap/AdvertisingMap.vue'
    import RecommendShop from '@/components/home/recommendShop/RecommendShop.vue'

    import {setTimeout} from 'timers';
    import Nav from '@/components/home/nav'

    export default {
        name: 'index',
        components: {
            AdvertisingMap,
            RecommendShop,
            Nav,
        },
        data() {
            return {
                menu: 'Nav',
                Arr: [],
                hengfuObj: "", //底部横幅图
                advUrl: "", //底部横幅图广告链接
                topTwoObj: "", //顶部二广告位
                hideTwoWrap: false,
                id1: "139",
                arrList: [], //分类数据
                arrList2: [], //2,3级数据
                isSave: true, //保存可用
                isDisable: false, //保存可用
                checked: true,
                form: {
                    "requirementDesc": "", //买家需求
                    "phone": '',//手机号
                },
                animate: false,
                input: "",
                banner: [],
                mySwiper: null,
                currentIndex: 0,
                goodItem: {
                    id: 255,
                    title: '孔雀精选',
                    imgName: 'mingpiantu'
                },
                AsideAd: [], //侧边广告图
                marqueeList: [], //滚动字幕
                boutiqueList: [], //精品专区数据
                dialogVisible: false, //获取报价弹窗显示与否
                timer: false, //延时器
            }
        },
        computed: {
            ...mapGetters(['user', 'isLogin', 'keywords'])
        },
        mounted() {
            let that = this;
            window.addEventListener('scroll', this.watchScroll);
            this.indexScroll();
            this.getIndex();

        },
        created: function () {
            setInterval(this.showMarquee, 2000);
            this.getBanner(); //获取轮播图
            this.getAsideAd(); //侧边广告图
            this.getScroll(); //侧边滚动字幕
            this.getBoutique(); //精品专区
            // this.getLocation();
            this.getTree1();
            this.gethengfu(); //底部横幅
            this.gettopTwoObj();//顶部二广告位
            // this.getTree2();
            if (this.$route.path != "") {
                this.isNavigation = false;
                this.iShowClass = false;
            }
            if (this.$route.path == "/") {
                this.isNavigation = true;
                this.iShowClass = true;
            }
        },
        updated() {

        },
        destroyed() {
            document.getElementsByClassName("search_content-wrap")[0].classList.remove('navBarWrap');
            let oHeader = document.getElementById("headers");
            oHeader.style.position = "fixed";
            window.removeEventListener("scroll", this.watchScroll); //离开首页销魂滚动事件
            if (this.timer) {
                clearTimeout(this.timer);
            }

        },
        methods: {
            goRenting() {
                this.$router.push("renting");
            },
            goClassPage(id) {
                this.$router.push({
                    name: "classSearch",
                    params: {
                        id: id
                    }
                });
                this.hideTwoWrap = false;
            },
            goTwoClass(id1) {

                // console.log(typeof(id1))
                this.getTree2(id1);
            },
            gettopTwoObj() { //获取顶部右侧二广告位
                this.$http.get('pic/adv/config/new/list?code=pc_sy_ycggzuxfggw').then(res => {
                    this.topTwoObj = res.data.data[0].picUrl;
                    // console.log(res.data.data[0].picUrl)
                })
            },
            gethengfu() { //获取底部横幅图片
                this.$http.get('pic/adv/config/new/list?code=pc_sy_db_ggw').then(res => {
                    this.hengfuObj = res.data.data[0].picUrl;
                    this.advUrl = res.data.data[0].advUrl;
                })
            },
            getTree1() { //获取分类结构
                this.$http.get('/productCate/getTopCompose').then(res => {
                    this.arrList = res.data.data;
                })
            },
            async getTree2(id) { //获取2，3级结构
                // let id=this.id1;
                this.arrList2 = JSON.parse(sessionStorage.getItem(id));

                if (this.arrList2 == null || this.arrList2.length <= 0) {
                    await this.$http.get('/productCate/underTopCateByCompose?id=' + id).then(res => {
                        sessionStorage.setItem(id, JSON.stringify(res.data.data));
                        this.arrList2 = JSON.parse(sessionStorage.getItem(id));

                    })
                } else {
                    this.arrList2 = JSON.parse(sessionStorage.getItem(id));
                }
            },
            hideNavMenuFun() { //隐藏二三级分类
                this.hideTwoWrap = false;
            },
            showNavMenuFun() {
                this.hideTwoWrap = true;
            },
            moveIn(id1) { //移入一级分类
                this.id1 = id1;
                this.getTree2(this.id1);

            },

            clickPrice() { //点击获取报价
                if (this.isLogin == "0") {
                    this.$message.error('请先登录!');
                    this.dialogVisible = false;
                    this.$router.push("/login");
                }
                if (this.input) {
                    this.form.requirementDesc = this.input;
                }
            },
            godetail(storeId, productId) { //首页去产品详情
                this.$router.push({path: '/productDetail', query: {sid: storeId, pid: productId}})
            },
            onSubmit() { //首页获取报价
                if (this.form.requirementDesc == "") {
                    this.$message.error('需求信息不能为空!');
                    return;
                }
                if (this.form.phone == "") {
                    this.$message.error('手机号不能为空!');
                    return;
                } else {
                    var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                    if (!reg.test(this.form.phone)) {
                        this.$message.error('手机号输入格式不正确！');
                        return;
                    }
                }
                this.$http.post('bidInfo/addBidInfoSpeediness', this.form).then(res => {
                    if (res.data.code == "0" || res.data.code == "200") {
                        this.$message({
                            message: '提交成功!',
                            type: 'success'
                        });
                        this.dialogVisible = false;
                        this.timer = setTimeout(() => {
                            this.$router.push("/myNeeds");
                        }, 1000)
                    }
                    if (res.data.code == "400") {
                        this.dialogVisible = false;
                    }
                })
            },

            getIndex() { //取消首页top值
                let oTop = document.getElementById("index");
                oTop.style.paddingTop = "0";
            },
            indexScroll() { //首页公用头部设置,改变固定定位
                let oHeader = document.getElementById("headers");
                oHeader.style.position = "static";
            },
            watchScroll() {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                //  当滚动超过 135 时，实现吸顶效果
                if (scrollTop >= 135) {
                    document.getElementsByClassName("search_content-wrap")[0].classList.add('navBarNone');

                }
                if (scrollTop > 240) { //滚动大于240px,
                    document.getElementsByClassName("search_content-wrap")[0].classList.add('navBarWrap');
                }
                if (scrollTop < 135) {
                    document.getElementsByClassName("search_content-wrap")[0].classList.remove('navBarNone');
                    document.getElementsByClassName("search_content-wrap")[0].classList.remove('navBarWrap');
                }
            },
            checkedAll(e) { //选中协议
                if (!e) {
                    this.isDisable = true;
                    this.isSave = false;
                }
                if (e) {

                    this.isDisable = false;
                    this.isSave = true;
                }
            },

            getBoutique() { //精品专区
                let Params = {
                    "pageNum": 0,
                    "pageSize": 8
                };
                this.$http.post('/good/config/list', Params).then(res => {
                    this.boutiqueList = res.data.data.list;
                })
            },
            getScroll() { //滚动字幕数据
                this.$http.post('hot/exchange/list').then(res => {
                    this.marqueeList = res.data.data;
                })
            },
            getBanner() {
                this.$http.get('pic/adv/config/new/list?code=pc_sy_db_lbt').then(res => {
                    this.banner = res.data.data;
                })
            },
            getAsideAd() {
                this.$http.get('pic/adv/config/new/list?code=pc_sy_cbl_ggw').then(res => {
                    this.AsideAd = res.data.data;
                })
            },
            goLogin() {
                this.$router.push("/login");
            },
            goRegist() {
                this.$router.push("/register");
            },
            showMarquee: function () { //滚动字幕
                this.animate = true;
                setTimeout(() => {
                    this.marqueeList.push(this.marqueeList[0]);
                    this.marqueeList.shift();
                    this.animate = false;
                }, 500)
            },

        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/common.scss';

    * {
        margin: 0;
        padding: 0;
    }

    h2 {
        line-height: 50px;
        font-size: 20px;
        color: #333;
        text-align: left;
        padding-left: 20px;
    }

    #index {
        text-align: center;
        position: relative;
        background-color: #f5f5f5;

        .main {
            width: 100%;
            background: url("https://imag.kqwad.com/upload/facd9cb4a56e4319abb1abd816a02bd0.png") no-repeat;
            background-size: 100%;
            background-position-x: 13px;

            padding-bottom: 80px;

            .carousel {
                .el-carousel__item h3 {
                    color: #475669;
                    font-size: 14px;
                    opacity: 0.75;
                    line-height: 150px;
                    margin: 0;
                }

                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }

                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
            }

            .upperHalf {
                height: 850px;
                width: 1200px;
                // background: red;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;

                .upperHalf-left {
                    width: 200px;
                    height: 100%;
                    position: relative;
                    background: #fff;

                    .firstWrap {
                        display: flex;
                        flex-direction: column;
                        // padding:0 20px;
                        > :nth-child(1) {
                            text-align: left;
                        }

                        > :nth-child(2) {
                            > div {
                                padding: 0 20px;
                            }
                        }

                        > :nth-child(1) {
                            // background: red;
                            // padding-left: 20px;
                            margin-left: 20px;
                            margin-top: 5px;
                            line-height: 38px;
                            font-size: 16px;
                            color: #333;
                            font-weight: 900;
                        }

                        .firstBox {
                            // margin-bottom: 20px;
                            width: 200px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding-bottom: 10px;
                            border-bottom: 1px dashed #e5e5e5;
                            line-height: 31px;
                            font-size: 14px;
                            color: #333;

                            > div {
                                width: 100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                > :nth-child(1) {
                                    margin-right: 20px;
                                }

                                &:hover {
                                    background: #eee;
                                }

                                span {
                                    width: 60px;
                                    text-align: center;
                                    font-size: 14px;
                                    color: #333;
                                    text-align: left;

                                    &:hover {
                                        color: #d82229;
                                        cursor: pointer;
                                    }
                                }
                            }

                        }


                    }

                    > :nth-child(4) {
                        .firstBox {
                            border: none;
                        }
                    }

                    .twoWrap {
                        width: 730px;
                        position: absolute;
                        left: 200px;
                        top: 0px;
                        z-index: 100;
                        background: #fff;
                        padding: 10px 0 20px 0;
                        border: 2px solid #d82229;
                        // border-top: 3px solid #d82229;
                        border-top: none;
                        min-height: 850px;

                        dl {
                            display: flex;
                            justify-content: flex-start;
                            padding: 0 60px 0 30px;

                            dt {
                                height: 26px;
                                width: 90px;
                                overflow: hidden;
                                flex-shrink: 0;
                                margin-right: 30px;
                                font-size: 14px;
                                color: #333;
                                line-height: 26px;
                                font-weight: 900;
                                margin-top: 12px;
                                cursor: pointer;

                                &:hover {
                                    color: #d82229;
                                }
                            }

                            dd {
                                width: 530px;
                                border-bottom: 1px dashed #e5e5e5;
                                padding: 12px 0;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;

                                span {
                                    margin-right: 20px;
                                    font-size: 14px;
                                    color: #666;
                                    line-height: 26px;
                                    cursor: pointer;

                                    &:hover {
                                        color: #d82229;
                                    }
                                }
                            }
                        }
                    }
                }

                .upperHalf-center {
                    width: 720px;
                    height: 100%;

                    .carousel {
                        width: 100%;
                        height: 300px;
                        position: relative;
                        // .el-carousel__container{
                        //   height:300px !important;
                        // }
                        img {
                            width: 100%;
                            height: 300px;
                        }

                        .button-prev-next {
                            display: none;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 1200px;
                            height: 60px;
                            transform: translate(-50%, -50%);
                            z-index: 1;

                            .swiper-button-prev {
                                color: #fff;
                            }
                        }

                        &:hover {
                            .button-prev-next {
                                display: block;
                            }
                        }
                    }

                    .boutiqueArea {
                        width: 100%;
                        height: 540px;
                        margin-top: 10px;
                        background: #fff;

                        .boutiqueArea-list {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding: 0 20px;

                            dl {
                                margin-top: 2px;
                                width: 160px;
                                height: 245px;

                                dt {
                                    width: 160px;
                                    height: 160px;
                                    overflow: hidden;
                                    cursor: pointer;

                                    img {
                                        display: block;
                                        width: 160px;
                                        height: 160px;
                                    }
                                }

                                dd {
                                    width: 100%;
                                    padding: 12px 0;

                                    p {
                                        line-height: 18px;
                                        color: #333;
                                        height: 34px;
                                        font-size: 14px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        text-align: left;
                                        cursor: pointer;

                                        &:hover {
                                            color: #d82229;
                                        }
                                    }

                                    > div {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        font-size: 14px;
                                        color: #333;
                                        line-height: 28px;

                                        :nth-child(1) {
                                            color: #e7211a;
                                        }

                                        :last-child {
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .upperHalf-right {
                    width: 260px;
                    height: 100%;

                    > div {
                        height: 300px;
                    }

                    .avatarWrap {
                        width: 100%;
                        height: 300px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #eee;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        background: #fff;

                        .avatarWrap-top {
                            width: 100%;
                            height: 190px;
                            padding: 0 15px;

                            > :nth-child(1) {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 13px 0;

                                img {
                                    display: block;
                                    width: 50px;
                                    height: 50px;
                                    margin-right: 12px;
                                }

                            }

                            > :nth-child(2) {
                                display: flex;
                                justify-content: center;
                                padding-bottom: 15px;
                                border-bottom: 1px solid #eee;

                                button {
                                    width: 80px;
                                    height: 24px;
                                    font-size: 14px;
                                    color: #fff;
                                    outline: none;
                                    border: none;
                                    text-align: center;
                                    line-height: 24px;
                                    margin: 0 10px;
                                    cursor: pointer;
                                    // border-radius: 4px;
                                }

                                > :nth-child(1) {
                                    background: #d82229;
                                }

                                > :nth-child(2) {
                                    background: #484848;
                                }

                                > :nth-child(3) {
                                    // width: 62px;
                                    padding-left: 18px;
                                    font-size: 16px;
                                    flex-shrink: 0;
                                    background: url("../../assets/image/qq.png") no-repeat 0px 3px;
                                    cursor: pointer;
                                }
                            }

                            .needs {
                                font-size: 14px;
                                color: #333;
                                text-align: left;
                                line-height: 32px;
                            }

                            /deep/ .quoteWrap {
                                display: flex;
                                justify-content: space-between;

                                .el-input {
                                    width: 158px;

                                    .el-input__inner {
                                        width: 158px;
                                        height: 30px;
                                        line-height: 30px;
                                        border: 1px solid #eee;
                                        border-radius: 0;
                                        padding-left: 6px;
                                    }

                                    > :nth-child(2) {
                                        cursor: pointer;
                                    }
                                }

                                > :last-child {
                                    width: 64px;
                                    line-height: 28px;
                                    height: 28px;
                                    border: 1px solid #d82229;
                                    color: #d82229;
                                    font-size: 14px;
                                }
                            }
                        }

                        .avatarWrap-bottom {
                            // width: 100%;
                            height: 107px;
                            overflow: hidden;
                            margin-top: 4px;
                            padding: 0 10px 10px;
                            cursor: pointer;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .exchange {
                        width: 260px;
                        height: 540px;
                        padding: 10px;
                        overflow: hidden;
                        background: #fff;

                        .integral-wrap {
                            overflow: hidden;

                            img {
                                display: block;
                                widows: 240px;
                                height: 114px;
                            }
                        }
                    }

                    .popular {
                        P {
                            line-height: 32px;
                            font-size: 14px;
                            color: #333;
                            font-weight: 900;
                            text-align: left;
                            margin-top: 4px;
                        }

                        .marquee_box {
                            .marquee_list {
                                height: 350px;
                                overflow: hidden;
                            }

                            li {
                                font-size: 14px;
                                margin-bottom: 10px;
                                line-height: 26px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: wrap;

                                span {
                                    color: #555;
                                    font-size: 14px;
                                }

                                > :nth-child(3) {
                                    color: #ff9800;
                                }

                                > :nth-child(5) {
                                    color: #d82229;
                                }

                                > :nth-child(6) {
                                    width: 100%;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }

            .can_need {
                margin: 20px auto;
                max-width: 1200px;
            }

            .hengfu {
                width: 1200px;
                height: 110px;
                overflow: hidden;
                margin: 0 auto;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .pagination {
        width: 100%;
        height: 20px;
        position: absolute;
        bottom: 20px;
        z-index: 99;

        div {
            cursor: pointer;
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 0 10px;
            background: #bdc0c0;

            &.active {
                background: #7ff046;
            }
        }
    }

    .Boutique {
        width: 1200px;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 7px;
    }


    /deep/ .el-dialog {
        width: 720px;
        height: 370px;

        .el-dialog__body {
            padding: 0 50px;

            .titles {
                display: flex;
                justify-content: flex-start;
                border-bottom: 1px solid #eee;
                margin-bottom: 20px;
                height: 48px;

                :nth-child(1) {
                    margin-right: 20px;
                    line-height: 20px;
                    font-size: 20px;
                    color: #333;
                    font-weight: 900;
                }

                :nth-child(2) {
                    font-size: 16px;
                    color: #333;
                }
            }

            .el-form-item {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 20px;

                .el-form-item__label {
                    width: 82px !important;
                    flex-shrink: 0;
                    font-size: 14px;
                    color: #333;
                }

                .el-input__inner {
                    width: 540px;
                }
            }

            .protocol {
                display: flex;
                margin-bottom: 30px;
                margin-left: 79px;

                .protocol-word {
                    color: #d82229;
                }
            }

            .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #999;
            }

            .is-disabled {
                width: 100%;
                height: 100%;
            }

            .el-checkbox {
                color: #999;
            }

            .save {
                display: flex;

                .el-form-item__content {
                    width: 540px;
                    height: 45px;
                    color: #fff;
                    font-size: 16px;
                    background: #d82229;
                    // margin: 0 auto;
                    border-radius: 4px;
                    margin-left: auto;
                    cursor: pointer;

                    .el-button {
                        width: 100%;
                        height: 100%;
                        background: #d82229;
                        border: none;
                    }
                }
            }
        }

        .is-disabled {
            background: #d82229;
            border: none;
            opacity: .5;
        }
    }


    // 以下是首页header重置样式
    .indexClass {
        position: static !important;
    }


    .twoWrap {
        width: 730px;
        // position:absolute;
        left: 200px;
        top: 1px;
        z-index: 10;
        background: #fff;
        padding: 10px 0 20px 0;
        border: 2px solid #d82229;
        // border-top: 3px solid #d82229;
        border-top: none;
        min-height: 610px;

        dl {
            display: flex;
            justify-content: flex-start;
            padding: 0 60px 0 30px;

            dt {
                height: 26px;
                width: 90px;
                overflow: hidden;
                flex-shrink: 0;
                margin-right: 30px;
                font-size: 14px;
                color: #333;
                line-height: 26px;
                font-weight: 900;
                margin-top: 12px;
                cursor: pointer;

                &:hover {
                    color: #d82229;
                }
            }

            dd {
                width: 530px;
                border-bottom: 1px dashed #e5e5e5;
                padding: 12px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                span {
                    margin-right: 20px;
                    font-size: 14px;
                    color: #666;
                    line-height: 26px;
                    cursor: pointer;

                    &:hover {
                        color: #d82229;
                    }
                }
            }
        }
    }
</style>
