<template>
  <div class="ad_move flex">
    <div class="swiper-container1" @mouseover="swiperOver" @mouseout="swiperOut">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in hotProduct" :key="item.id">
          <img :src="item.picUrl | imagePath"  @click="goClassPage(item.cateId)">
        </div>
      </div>
      <!-- 左右箭头 -->
      <div class="button-prev-next1" v-show="showArrow">
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
      </div>
    </div>
  </div>
</template>

<script type='text/ecmascript-6'>

import * as api from "@/api/api"
import Swiper from 'swiper'
// import '@/swiper/css/swiper.min.css'
export default {
  components: {},
  data() {
    return {
      hotProduct: [],
      showArrow: false,
      mySwiper: null
    }
  },
  mounted() {
    this.cateRecommend()
  },
  methods: {
    renderSwiper() {
      this.mySwiper = new Swiper('.swiper-container1', {
        speed: 1000,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: this.hotProduct.length,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    },
    cateRecommend() {
      let self = this;
      api.cateRecommend().then(res => {
        if (res.data.code == 0) {
            // console.log(res.data.data)
          self.hotProduct = res.data.data
          //  console.log(self.hotProduct)
          setTimeout(() => {
            this.renderSwiper()
          }, 10)
        }
      })
    },
    swiperOver() {
      this.mySwiper.autoplay.stop()
      this.showArrow = true
    },
    swiperOut() {
      this.mySwiper.autoplay.start()
      this.showArrow = false
    },
    // productDetail(id) {
    //   console.log(id)
    //   this.$router.push({
    //     path: '/classSearch',
    //     query:{
    //       // firstLevel:id,
    //       // secondLevel:-1,
    //       // thirdLevel:-1,
    //       // level:1
    //       }
    //     });
    // },
    goClassPage(id){
      this.$router.push({
        name:"classSearch",
        params:{
          id:id
        }
      })
    },
  }
}
</script>

<style lang='scss' scoped>
.ad_move {
  max-width: 1200px;
  width: 100%;
  height: 140px;
  margin: 0 auto;
  margin-top: 15px;
  // margin: 20px auto 40px;
  .swiper-container1 {
    width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .swiper-slide {
      width: 290px !important;
      height: 140px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .button-prev-next1 {
      position: absolute;
      width: 100%;
      top: 50%;
      z-index: 1;
    }
    .swiper-wrapper{
      display: flex;
      justify-content: space-between;
      width: 1200px;
    }
  }
}
</style>
